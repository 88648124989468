<template>
    <div>
        <Button icon="pi pi-upload" @click="(bukti = true), get_bukti()" />
        <Dialog header="Upload Bukti Pengembalian" v-model:visible="bukti" :breakpoints="{ '960px': '75vw' }" :style="{ width: '75vw' }" :modal="true">
            <div class="grid">
                <div class="col-6">
                    <span class="p-float-label mt-4">
                        <FileUpload
                            ref="file1"
                            id="foto"
                            name="Foto"
                            :fileLimit="1"
                            :multiple="false"
                            accept="image/*"
                            :maxFileSize="10000000"
                            :showUploadButton="false"
                            :previewWidth="100"
                            chooseLabel="Pilih Foto"
                            cancelLabel="Batal"
                            @change="handleFile()"
                            @remove="src1 = null"
                            @clear="src1 = null"
                        >
                            <template #empty>
                                <p>Pilih File Foto Bukti</p>
                            </template>
                        </FileUpload>
                        <!-- <small v-if="v$.data_bukti.file_bukti.$invalid" :class="{ 'p-error': v$.data_bukti.file_bukti.$invalid ? !v$.data_bukti.file_bukti.$anyError : null }">{{ checkValidasi('data_bukti', 'file_skkm') }}</small> -->
                    </span>
                </div>
                <div class="col-6">
                    <div class="grid p-fluid">
                        <div class="col">
                            <span class="flex justify-content-center align-items-center mt-4">
                                <Image :src="src1" alt="" imageStyle="height: 200px; width: 200px" preview />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="grid mt-4 mb-4">
                    <div class="col-4 col-offset-4" v-if="!data_skkm.pool_skkm_id">
                        <Button label="Simpan" @click="regis_skkm()" icon="" class="p-button-success" />
                    </div>
                    <div class="col-4 col-offset-4" v-else>
                        <Button label="Simpan Perubahan" @click="update_bukti()" icon="" class="p-button-success" />
                    </div>
                </div> -->
            <template #footer>
                <Button label="Batal" @click="bukti = false" icon="" class="p-button-secondary" />
                <Button label="Unggah" @click="update_bukti()" icon="" class="p-button-success" />
            </template>
        </Dialog>
    </div>
</template>
<script>
// import useVuelidate from '@vuelidate/core';
// import { required, requiredIf } from '@vuelidate/validators';
export default {
    props: ['data'],
    // setup() {
    //     return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    // },
    data() {
        return {
            bukti: false,
            loading1: false,
            file1: null,
            src1: null,
        };
    },
    emits: {
        alert: null,
    },
    methods: {
        handleFile() {
            this.file1 = this.$refs.file1.files[0];
            this.src1 = URL.createObjectURL(this.file1);
        },
        get_bukti() {
            let vm = this;
            this.src1 = vm.$ip2 + vm.data.bukti_pembayaran;
        },
        update_bukti() {
            let vm = this;
            let formData = new FormData();
            formData.append('id', vm.data.id);
            if (vm.file1) {
                formData.append('file1', vm.file1);
            }
            vm.$axiosbilling
                .post('pengembalian_dana/update', formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Upload Bukti Pengembalian Dana', life: 3000 });
                            vm.file1 = null;
                            vm.src1 = null;
                            vm.bukti = false;
                            vm.$emit('alert')
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
    },
};
</script>

<style scoped>
::v-deep(.reject) {
    background-color: #fac9da;
}
</style>
